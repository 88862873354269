import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    
    <div>
      <img src="/images/webinar-1.png"/>
    </div>
  <Container>
  
  <div className="grid lg:grid-cols-2 md:grid-cols-2 webv1 gap-4 place-items-center pad1rr">
  <div className="items-end ml20"> 
  <h1 className="mt-4 bg2">Lorem ipsum dolor
sit ame</h1>
  <p className="mt-4 fs12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
  <button className="bg-black-500  btn text-white font-bold py-2 px-4 rounded">
  Click Here
</button>
  </div>
  <div>
 
<div className="bg-white rounded-lg border shadow-md webcard sm:p-border-gray-700">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-3 ">
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0">
                      <h1 className="webtitle">Laurem ipsum</h1>
                        <p className="webp text-sm font-medium text-gray-900  dark:text-white">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>

<div className=" bg-white rounded-lg border shadow-md webcard sm:p-8 dark:bg-gray-800 dark:border-gray-700">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-3 ">
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0">
                      <h1 className="webtitle">Laurem ipsum</h1>
                        <p className="webp text-sm font-medium text-gray-900  dark:text-white">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>
<div className=" bg-white rounded-lg border shadow-md webcard sm:p-8 dark:bg-gray-800 dark:border-gray-700">
  
   <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
            <li className="py-3 ">
                <div className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                        <img className="w-16 h-16 rounded-full" src="/images/webinar-3.png" alt="Neil image"/>
                    </div>
                    <div className="flex-1 min-w-0">
                      <h1 className="webtitle">Laurem ipsum</h1>
                        <p className="webp text-sm font-medium text-gray-900  dark:text-white">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                       
                    </div>
                   
                </div>
            </li>
          
          
          
          
        </ul>
   </div>
</div>

    
  </div>
  
</div>    
</Container>
<br/>
<Container>
<header
  className="webvedio webv relative flex items-center justify-center  lg:mb-12 mb5 overflow-hidden"
>
  <div
    className="relative z-30  text-2xl text-white bg-purple-300 bg-opacity-50 "
  >
    Welcome to my site!
  </div>
  <video
    autoplay
    
    
    className="absolute z-10 "
  >
    <source
      src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</header>
</Container>





  
  </Layout>
)

export default IndexPage
